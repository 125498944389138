import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['target']

  scroll() {
    window.scrollTo({
      top: this.targetTarget.offsetTop,
    })
  }
}
